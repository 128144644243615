<template lang="pug">
TableV2(
  :headers="headers"
  :items="items.results"
  :pageCount="items.count"
  :currentPage="items.current"
  :querySearch="getMedicalStatementReport"
  :isLoading="isLoading"
  :saveReport="getReportStatementsMedicalExcel"
)
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data () {
    return {
      headers: [
        { value: 'number',
          text: this.$t('tableHeaders.number'),
          sortable: false },
        { value: 'sailorFullName',
          text: this.$t('tableHeaders.sailor_full_name'),
          sortable: false },
        { value: 'created_at',
          text: this.$t('tableHeaders.created_at'),
          sortable: false },
        { value: 'medical_institution',
          text: this.$t('tableHeaders.medical_institution'),
          sortable: false },
        { value: 'medicalPosition',
          text: this.$t('tableHeaders.position'),
          sortable: false },
        { value: 'status_document',
          text: this.$t('tableHeaders.status_document'),
          sortable: false }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.medical_statemets,
      isLoading: state => state.report.isLoadingReport
    })
  },
  methods: {
    ...mapActions(['getMedicalStatementReport', 'getReportStatementsMedicalExcel'])
  }
}
</script>
